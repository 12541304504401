import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import { VerifyPasswordUseCaseState } from './password-verification.state';
import { VERIFY_PASSWORD_USE_CASE } from '@module/assignment';
import { AssignmentDetailPageState } from '../assignment-detail.state';

export const handleVerifyPasswordUseCase: UseCaseStateGenerator<VerifyPasswordUseCaseState> = {
  name: VERIFY_PASSWORD_USE_CASE,
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      isLoadingVerifyPassword: true,
      verifyPasswordStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      isLoadingVerifyPassword: false,
      verifyPasswordStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingVerifyPassword: false,
      verifyPasswordStatus: RequestStatus.ERROR,
    };
  },
};

export const passwordVerificationHandlers = [handleVerifyPasswordUseCase];
