import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { RootReducerState } from 'redux/root.reducer';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import { HomeworkSubmissionPreviewPageStatus } from './homework-submission-preview.reducer';
import { UseCaseError } from '@digibricks/domains';

export const getSubmissionPreviewStatusSelector = (
  state: RootReducerState,
): HomeworkSubmissionPreviewPageStatus =>
  state.homeworkSubmissionPreviewPage.getHomeworkSubmissionPreviewStatus;

// Submission
export const submissionSelector = (state: RootReducerState): SubmissionSummary =>
  state.homeworkSubmissionPreviewPage.submission;

// Assignment
export const assignmentSelector = (state: RootReducerState): Assignment<AssignmentProps> =>
  state.homeworkSubmissionPreviewPage.assignment;

// Form Release
export const formReleaseSelector = (state: RootReducerState): FormRelease =>
  state.homeworkSubmissionPreviewPage.formRelease;

// Attendee
export const attendeeSelector = (state: RootReducerState): AttendeeDetail =>
  state.homeworkSubmissionPreviewPage.attendeeDetail;

// Question Release
export const questionReleasesSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => state.homeworkSubmissionPreviewPage.questionReleases;

// Answer
export const submissionAnswersSelector = (
  state: RootReducerState,
): (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[] =>
  state.homeworkSubmissionPreviewPage.answers;

// Error
export const errorSelector = (state: RootReducerState): UseCaseError<any> =>
  state.homeworkSubmissionPreviewPage.error;
