import { RequestStatus } from '@lib/plugin-redux-core';
import {
  ExaminationAssignment,
  HomeworkAssignment,
  PracticeAssignment,
  SubmissionSummary,
} from '@module/assignment';
import { UseCaseError } from '@digibricks/domains';
import { User } from '@module/user';
import { PasswordVerificationState } from './password-verification';
import { StartSubmissionState } from './start-submission';

export type AssignmentDetailPageInitialState = {
  error?: UseCaseError<any>;
};
export type GetAssignmentByIdUseCaseState = {
  getAssignmentStatus: RequestStatus;
  assignment: PracticeAssignment | HomeworkAssignment | ExaminationAssignment;
  submissions: SubmissionSummary[];
  assigner: User;
};

export type AssignmentDetailPageState = AssignmentDetailPageInitialState &
  GetAssignmentByIdUseCaseState &
  PasswordVerificationState &
  StartSubmissionState;

export const initialState: AssignmentDetailPageInitialState = {
  error: null,
};
