import {
  GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
  IParticipatedAssignmentRepository,
  GetParticipatedAssignmentUseCase,
  PARTICIPATED_ASSIGNMENT_RESPONDER_REPOSITORY,
} from '@module/assignment';

export const participatedAssignmentUseCaseProviders = [
  {
    provide: GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
    useFactory: (participatedAssignmentRepository: IParticipatedAssignmentRepository) => {
      return new GetParticipatedAssignmentUseCase(participatedAssignmentRepository);
    },
    inject: [PARTICIPATED_ASSIGNMENT_RESPONDER_REPOSITORY],
  },
];
