import {
  AssignmentApi,
  ASSIGNMENT_API,
  ParticipatedAssignmentApi,
  PARTICIPATED_ASSIGNMENT_API,
  PracticeSubmissionApi,
  PRACTICE_SUBMISSION_API,
  SubmissionApi,
  SUBMISSION_API,
  HOMEWORK_SUBMISSION_API,
  HomeworkSubmissionApi,
  EXAMINATION_ASSIGNMENT_API,
  ExaminationSubmissionApi,
  EXAMINATION_SUBMISSION_API,
} from '@module/assignment';
import {
  EXTERNAL_FILE_STORAGE_API,
  ExternalFileStorageApi,
  FILE_STORAGE_API,
  FileStorageApi,
} from '@module/file-storage';
import {
  FORM_RELEASE_API,
  QuestionReleasesApi,
  QUESTION_RELEASES_API,
  SubjectApi,
  SUBJECT_API,
  FormReleaseApi,
  FORM_API,
  FormApi,
  FormApiMapper,
  SectionApiMapper,
} from '@module/form';
import { AuthApi, AUTH_API, UserApi, USER_API } from '@module/user';
import { AxiosInstanceBuilder } from '@digibricks/plugin-axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const timeout = publicRuntimeConfig.api.timeout;

export const authApiProvider = {
  provide: AUTH_API,
  useFactory: () => {
    return new AuthApi(
      new AxiosInstanceBuilder()
        .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
        .withTimeout(timeout)
        .toInstance(),
    );
  },
};

export const apiProviders = [
  authApiProvider,
  {
    provide: SectionApiMapper,
    useValue: new SectionApiMapper(),
  },
  {
    provide: FormApiMapper,
    useFactory: (sectionApiMapper: SectionApiMapper) => new FormApiMapper(sectionApiMapper),
    inject: [SectionApiMapper],
  },
  {
    provide: USER_API,
    useFactory: () => {
      return new UserApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_RELEASE_API,
    useFactory: () => {
      return new FormReleaseApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_API,
    useFactory: (sectionApiMapper: SectionApiMapper, formApiMapper: FormApiMapper) => {
      return new FormApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        sectionApiMapper,
        formApiMapper,
      );
    },
    inject: [SectionApiMapper, FormApiMapper],
  },
  {
    provide: QUESTION_RELEASES_API,
    useFactory: () => {
      return new QuestionReleasesApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_API,
    useFactory: () => {
      return new AssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBMISSION_API,
    useFactory: () => {
      return new SubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_SUBMISSION_API,
    useFactory: () => {
      return new PracticeSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: HOMEWORK_SUBMISSION_API,
    useFactory: () => {
      return new HomeworkSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXAMINATION_SUBMISSION_API,
    useFactory: () => {
      return new ExaminationSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXAMINATION_ASSIGNMENT_API,
    useFactory: () => {
      return new ExaminationSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PARTICIPATED_ASSIGNMENT_API,
    useFactory: () => {
      return new ParticipatedAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBJECT_API,
    useFactory: () => {
      return new SubjectApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FILE_STORAGE_API,
    useFactory: () => {
      return new FileStorageApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFileStorageUrl)
          .withTimeout(20000)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXTERNAL_FILE_STORAGE_API,
    useFactory: () => {
      return new ExternalFileStorageApi(new AxiosInstanceBuilder().withTimeout(0).toInstance());
    },
  },
];
