import { combineReducers } from 'redux';
import { userReducer } from './user';
import { authReducer } from './auth';
import { AssignmentDetailPageState, assignmentDetailReducer } from './assignment';
import { sidebarReducer } from './sidebar';
import { dashboardReducer, DashboardState } from './dashboard';
import { SubmissionPageState, submissionReducer } from './submission';
import { pageLayoutReducer, PageLayoutState } from './layout';
import { toastReducer, ToastState } from './toast';
import { SubmissionPreviewPageState, submissionPreviewReducer } from './submission-preview';
import { SubmissionSubmitPageState, submissionSubmitReducer } from './submission-submit';
import {
  HomeworkSubmissionPreviewPageState,
  homeworkSubmissionPreviewReducer,
} from './homework-submission-preview';
import {
  ExaminationSubmissionPreviewPageState,
  examinationSubmissionPreviewReducer,
} from './examination-submission-preview';

export type RootReducerState = {
  //Todo: Refactor type for each reducer has any type
  auth: any;
  user: any;
  pageLayout: PageLayoutState;
  toast: ToastState;
  assignmentDetailPage: AssignmentDetailPageState;
  submissionPage: SubmissionPageState;
  sidebar: any;
  dashboardPage: DashboardState;
  submissionPreviewPage: SubmissionPreviewPageState;
  submissionSubmitPage: SubmissionSubmitPageState;
  homeworkSubmissionPreviewPage: HomeworkSubmissionPreviewPageState;
  examinationSubmissionPreviewPage: ExaminationSubmissionPreviewPageState;
};

export const rootReducer = combineReducers<RootReducerState>({
  auth: authReducer,
  user: userReducer,
  toast: toastReducer,
  pageLayout: pageLayoutReducer,
  assignmentDetailPage: assignmentDetailReducer,
  submissionPage: submissionReducer,
  submissionPreviewPage: submissionPreviewReducer,
  sidebar: sidebarReducer,
  dashboardPage: dashboardReducer,
  submissionSubmitPage: submissionSubmitReducer,
  homeworkSubmissionPreviewPage: homeworkSubmissionPreviewReducer,
  examinationSubmissionPreviewPage: examinationSubmissionPreviewReducer,
});
