import {
  IAuthenticationRepository,
  SignOutForAppUseCase,
  AUTHENTICATION_REPOSITORY,
  GetAccessTokenUseCase,
  GET_ACCESS_TOKEN_USE_CASE,
  SIGN_OUT_USE_CASE,
} from '@module/user';

export const authenticationUseCaseProviders = [
  {
    provide: SIGN_OUT_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new SignOutForAppUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: GET_ACCESS_TOKEN_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new GetAccessTokenUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
];
