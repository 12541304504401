import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  AssignmentDetailPageState,
  GetAssignmentByIdUseCaseState,
} from './assignment-detail.state';
import { GET_RESPONDER_ASSIGNMENT_USE_CASE } from '@module/assignment';

const handleGetResponderAssignmentUseCase: UseCaseStateGenerator<GetAssignmentByIdUseCaseState> = {
  name: GET_RESPONDER_ASSIGNMENT_USE_CASE,
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      getAssignmentStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { assignment, assigner, submissions } = action.payload;
    return {
      ...state,
      assignment,
      assigner,
      submissions,
      getAssignmentStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAssignmentStatus: RequestStatus.ERROR,
    };
  },
};

export const commonHandlers = [handleGetResponderAssignmentUseCase];
