import { createReducer } from '@lib/plugin-redux-core';
import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_HOMEWORK_SUBMISSION_PREVIEW_USE_CASE,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { UseCaseError } from '@digibricks/domains';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import {
  handleGetAssignmentByIdUseCase,
  handleGetSubmissionPreviewUseCase,
} from './homework-submission-preview.handler';

export enum HomeworkSubmissionPreviewPageStatus {
  EXECUTING,
  SUCCESS,
  ERROR,
  RESET,
}

export type GetHomeworkSubmissionPreviewUseCaseState = {
  submission: SubmissionSummary;
  formRelease: FormRelease;
  attendeeDetail: AttendeeDetail;
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
  answers: (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[];
  getHomeworkSubmissionPreviewStatus: HomeworkSubmissionPreviewPageStatus;
};

type GetAssignmentByIdUseCaseState = {
  getAssignmentByIdStatus: HomeworkSubmissionPreviewPageStatus;
  assignment: Assignment<AssignmentProps>;
};
export type SubmissionPreviewPageInitialState = {
  error?: UseCaseError<any>;
};

export type HomeworkSubmissionPreviewPageState = SubmissionPreviewPageInitialState &
  GetHomeworkSubmissionPreviewUseCaseState &
  GetAssignmentByIdUseCaseState;

export const initialState: SubmissionPreviewPageInitialState = {
  error: null,
};

export const homeworkSubmissionPreviewReducer = createReducer(initialState, [
  {
    name: GET_HOMEWORK_SUBMISSION_PREVIEW_USE_CASE,
    ...handleGetSubmissionPreviewUseCase,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
]);
