import { Action } from '@lib/plugin-redux-core';
import {
  ExaminationSubmissionPreviewPageState,
  ExaminationSubmissionPreviewPageStatus,
} from './examination-submission-preview.reducer';
import { QuestionReleases } from '@module/form';

export const handleGetSubmissionPreviewUseCase = {
  executing: (
    state: ExaminationSubmissionPreviewPageState,
  ): ExaminationSubmissionPreviewPageState => {
    return {
      ...state,
      getExaminationSubmissionPreviewStatus: ExaminationSubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (
    state: ExaminationSubmissionPreviewPageState,
    action: Action,
  ): ExaminationSubmissionPreviewPageState => {
    const { payload } = action;
    const { questionReleases } = payload;
    const sortedQuestionReleases = QuestionReleases.sortQuestions(questionReleases);

    return {
      ...state,
      submission: payload.submission,
      formRelease: payload.formRelease,
      attendeeDetail: payload.attendee,
      questionReleases: sortedQuestionReleases,
      answers: payload.answers,
      getExaminationSubmissionPreviewStatus: ExaminationSubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (
    state: ExaminationSubmissionPreviewPageState,
    action: Action,
  ): ExaminationSubmissionPreviewPageState => {
    const { error } = action;

    return {
      ...state,
      getExaminationSubmissionPreviewStatus: ExaminationSubmissionPreviewPageStatus.ERROR,
      error,
    };
  },

  reset: (state: ExaminationSubmissionPreviewPageState): ExaminationSubmissionPreviewPageState => {
    return {
      ...state,
      getExaminationSubmissionPreviewStatus: ExaminationSubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetAssignmentByIdUseCase = {
  executing: (
    state: ExaminationSubmissionPreviewPageState,
  ): ExaminationSubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: ExaminationSubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (
    state: ExaminationSubmissionPreviewPageState,
    action: Action,
  ): ExaminationSubmissionPreviewPageState => {
    const { payload } = action;

    return {
      ...state,
      getAssignmentByIdStatus: ExaminationSubmissionPreviewPageStatus.SUCCESS,
      assignment: payload,
    };
  },
  error: (
    state: ExaminationSubmissionPreviewPageState,
    action: Action,
  ): ExaminationSubmissionPreviewPageState => {
    const { error } = action;

    return {
      ...state,
      error,
      getAssignmentByIdStatus: ExaminationSubmissionPreviewPageStatus.ERROR,
    };
  },
  reset: (state: ExaminationSubmissionPreviewPageState): ExaminationSubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: ExaminationSubmissionPreviewPageStatus.RESET,
    };
  },
};
