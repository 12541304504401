import {
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
  CREATE_HOMEWORK_SUBMISSION_USE_CASE,
  CreateHomeworkSubmissionUseCase,
  SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
  SubmitHomeworkSubmissionsUseCase,
  IHomeworkSubmissionRepository,
  HOMEWORK_SUBMISSION_REPOSITORY,
  UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
  UpdateHomeworkSubmissionUseCase,
} from '@module/assignment';

export const homeworkSubmissionUseCaseProviders = [
  {
    provide: CREATE_HOMEWORK_SUBMISSION_USE_CASE,
    useFactory: (
      homeworkSubmissionRepository: IHomeworkSubmissionRepository,
      assignmentRepository: IAssignmentRepository,
    ) => {
      return new CreateHomeworkSubmissionUseCase(
        homeworkSubmissionRepository,
        assignmentRepository,
      );
    },
    inject: [HOMEWORK_SUBMISSION_REPOSITORY, ASSIGNMENT_REPOSITORY],
  },
  {
    provide: SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
    useFactory: (homeworkSubmissionRepository: IHomeworkSubmissionRepository) => {
      return new SubmitHomeworkSubmissionsUseCase(homeworkSubmissionRepository);
    },
    inject: [HOMEWORK_SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
    useFactory: (homeworkSubmissionRepository: IHomeworkSubmissionRepository) => {
      return new UpdateHomeworkSubmissionUseCase(homeworkSubmissionRepository);
    },
    inject: [HOMEWORK_SUBMISSION_REPOSITORY],
  },
];
