import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { SubmissionPreviewPageStatus } from './submission-preview.reducer';

export const getSubmissionPreviewStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getSubmissionPreviewStatus;

// Submission
export const submissionSelector = (state: RootReducerState): SubmissionSummary =>
  state.submissionPreviewPage.submission;

// Assignment
export const assignmentSelector = (state: RootReducerState): Assignment<AssignmentProps> =>
  state.submissionPreviewPage.assignment;

// Form Release
export const formReleaseSelector = (state: RootReducerState): FormRelease =>
  state.submissionPreviewPage.formRelease;

// Attendee
export const attendeeSelector = (state: RootReducerState): AttendeeDetail =>
  state.submissionPreviewPage.attendeeDetail;

// Question Release
export const questionReleasesSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => state.submissionPreviewPage.questionReleases;

// Answer
export const submissionAnswersSelector = (
  state: RootReducerState,
): (
  | EssayAnswer
  | SingleChoiceAnswer
  | FillInBlankGroupAnswer
  | MultipleChoiceAnswer
  | MatchingAnswerGroup
)[] => state.submissionPreviewPage.answers;
