import { createReducer } from '@lib/plugin-redux-core';
import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  GET_SUBMISSION_PREVIEW_USE_CASE,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import { UseCaseError } from '@digibricks/domains';
import { handleGetSubmissionPreviewUseCase } from './submission-preview.handler';

export enum SubmissionPreviewPageStatus {
  EXECUTING,
  SUCCESS,
  ERROR,
  RESET,
}

export type GetSubmissionPreviewUseCaseState = {
  submission: SubmissionSummary;
  assignment: Assignment<AssignmentProps>;
  formRelease: FormRelease;
  attendeeDetail: AttendeeDetail;
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
  answers: (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[];
  getSubmissionPreviewStatus: SubmissionPreviewPageStatus;
};
export type SubmissionPreviewPageInitialState = {
  error?: UseCaseError<any>;
};

export type SubmissionPreviewPageState = SubmissionPreviewPageInitialState &
  GetSubmissionPreviewUseCaseState;

export const initialState: SubmissionPreviewPageInitialState = {
  error: null,
};

export const submissionPreviewReducer = createReducer(initialState, [
  {
    name: GET_SUBMISSION_PREVIEW_USE_CASE,
    ...handleGetSubmissionPreviewUseCase,
  },
]);
