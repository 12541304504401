import { Action } from '@lib/plugin-redux-core';
import {
  HomeworkSubmissionPreviewPageState,
  HomeworkSubmissionPreviewPageStatus,
} from './homework-submission-preview.reducer';
import { QuestionReleases } from '@module/form';

export const handleGetSubmissionPreviewUseCase = {
  executing: (state: HomeworkSubmissionPreviewPageState): HomeworkSubmissionPreviewPageState => {
    return {
      ...state,
      getHomeworkSubmissionPreviewStatus: HomeworkSubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (
    state: HomeworkSubmissionPreviewPageState,
    action: Action,
  ): HomeworkSubmissionPreviewPageState => {
    const { payload } = action;
    const { questionReleases } = payload;
    const sortedQuestionReleases = QuestionReleases.sortQuestions(questionReleases);

    return {
      ...state,
      submission: payload.submission,
      formRelease: payload.formRelease,
      attendeeDetail: payload.attendee,
      questionReleases: sortedQuestionReleases,
      answers: payload.answers,
      getHomeworkSubmissionPreviewStatus: HomeworkSubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (
    state: HomeworkSubmissionPreviewPageState,
    action: Action,
  ): HomeworkSubmissionPreviewPageState => {
    const { error } = action;

    return {
      ...state,
      error,
      getHomeworkSubmissionPreviewStatus: HomeworkSubmissionPreviewPageStatus.ERROR,
    };
  },
  reset: (state: HomeworkSubmissionPreviewPageState): HomeworkSubmissionPreviewPageState => {
    return {
      ...state,
      getHomeworkSubmissionPreviewStatus: HomeworkSubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetAssignmentByIdUseCase = {
  executing: (state: HomeworkSubmissionPreviewPageState): HomeworkSubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: HomeworkSubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (
    state: HomeworkSubmissionPreviewPageState,
    action: Action,
  ): HomeworkSubmissionPreviewPageState => {
    const { payload } = action;

    return {
      ...state,
      getAssignmentByIdStatus: HomeworkSubmissionPreviewPageStatus.SUCCESS,
      assignment: payload,
    };
  },
  error: (
    state: HomeworkSubmissionPreviewPageState,
    action: Action,
  ): HomeworkSubmissionPreviewPageState => {
    const { error } = action;

    return {
      ...state,
      error,
      getAssignmentByIdStatus: HomeworkSubmissionPreviewPageStatus.ERROR,
    };
  },
  reset: (state: HomeworkSubmissionPreviewPageState): HomeworkSubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: HomeworkSubmissionPreviewPageStatus.RESET,
    };
  },
};
