import {
  ASSIGNMENT_REPOSITORY,
  IAssignmentRepository,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GetAssignmentByIdUseCase,
  GET_RESPONDER_ASSIGNMENT_USE_CASE,
  GetResponderAssignmentUseCase,
  GetSubmissionsResponderUseCase,
  GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
  VERIFY_PASSWORD_USE_CASE,
  VerifyPasswordUseCase,
  GET_ASSIGNMENTS_FOR_RESPONDER_USE_CASE,
  GetAssignmentsForResponderUseCase,
} from '@module/assignment';
import { GET_USER_BY_ID_USE_CASE, GetUserByIdUseCase } from '@module/user';

export const assignmentUseCaseProviders = [
  {
    provide: GET_ASSIGNMENT_BY_ID_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ASSIGNMENTS_FOR_RESPONDER_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentsForResponderUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_RESPONDER_ASSIGNMENT_USE_CASE,
    useFactory: (
      getAssignmentByIdUseCase: GetAssignmentByIdUseCase,
      getUserByIdUseCase: GetUserByIdUseCase,
      getSubmissionsResponderUseCase: GetSubmissionsResponderUseCase,
    ) => {
      return new GetResponderAssignmentUseCase(
        getAssignmentByIdUseCase,
        getUserByIdUseCase,
        getSubmissionsResponderUseCase,
      );
    },
    inject: [
      GET_ASSIGNMENT_BY_ID_USE_CASE,
      GET_USER_BY_ID_USE_CASE,
      GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
    ],
  },
  {
    provide: VERIFY_PASSWORD_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new VerifyPasswordUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
];
