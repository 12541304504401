import {
  IPracticeSubmissionRepository,
  PRACTICE_SUBMISSION_REPOSITORY,
  SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  SubmitPracticeSubmissionsUseCase,
  CREATE_PRACTICE_SUBMISSION_USE_CASE,
  CreatePracticeSubmissionUseCase,
  PAUSE_PRACTICE_SUBMISSION_USE_CASE,
  PausePracticeSubmissionUseCase,
  RESUME_PRACTICE_SUBMISSION_USE_CASE,
  ResumePracticeSubmissionUseCase,
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
  PracticeSubmissionRepository,
  UpdatePracticeSubmissionUseCase,
  UPDATE_PRACTICE_SUBMISSION_USE_CASE,
} from '@module/assignment';
import { inject } from '@vercel/analytics/*';

export const practiceSubmissionUseCaseProviders = [
  {
    provide: CREATE_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (
      practiceSubmissionRepository: IPracticeSubmissionRepository,
      assignmentRepository: IAssignmentRepository,
    ) => {
      return new CreatePracticeSubmissionUseCase(
        practiceSubmissionRepository,
        assignmentRepository,
      );
    },
    inject: [PRACTICE_SUBMISSION_REPOSITORY, ASSIGNMENT_REPOSITORY],
  },
  {
    provide: SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (practiceSubmissionRepository: IPracticeSubmissionRepository) => {
      return new SubmitPracticeSubmissionsUseCase(practiceSubmissionRepository);
    },
    inject: [PRACTICE_SUBMISSION_REPOSITORY],
  },
  {
    provide: PAUSE_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (practiceSubmissionRepository: IPracticeSubmissionRepository) => {
      return new PausePracticeSubmissionUseCase(practiceSubmissionRepository);
    },
    inject: [PRACTICE_SUBMISSION_REPOSITORY],
  },
  {
    provide: RESUME_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (practiceSubmissionRepository: IPracticeSubmissionRepository) => {
      return new ResumePracticeSubmissionUseCase(practiceSubmissionRepository);
    },
    inject: [PRACTICE_SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (practiceSubmissionRepository: IPracticeSubmissionRepository) => {
      return new UpdatePracticeSubmissionUseCase(practiceSubmissionRepository);
    },
    inject: [PRACTICE_SUBMISSION_REPOSITORY],
  },
];
