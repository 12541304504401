import { Action, createReducer } from '@lib/plugin-redux-core';
import { UserSummary } from '@module/user';
import { GET_USERS_USE_CASE, SIGN_OUT_USE_CASE } from '../../redux/root.action';

const initialState = {
  userResponse: {},
  listUsers: [],
  isLoadingListUsers: false,
  updateProfileResponse: '',
  changePasswordResponse: '',
  isSignOutSuccess: false,
  isLoading: false,
  error: '',
};

export const userReducer = createReducer(initialState, [
  {
    name: SIGN_OUT_USE_CASE,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    executing: (state: {}, action: Action) => {
      return { ...state };
    },
    success: () => ({ ...initialState, isSignOutSuccess: true }),
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error: error,
        isSignOutSuccess: false,
      };
    },
  },
  {
    name: GET_USERS_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        payload,
        isLoadingListUsers: true,
      };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        listUsers: payload as UserSummary[],
        isLoadingListUsers: false,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return { ...state, error, isLoadingListUsers: false };
    },
  },
]);
