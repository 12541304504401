import { createReducer } from '@lib/plugin-redux-core';
import { commonHandlers } from './submission.handler';
import { SubmissionPageInitialState } from './submission.state';
import { countdownHandlers } from './countdown';
import { questionHandlers } from './question';
import { sectionHandlers } from './section';
import { submissionHandlers } from './section-summary';

export const enum SubmissionPageStatus {
  EXECUTE = 'EXECUTE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RESET = 'RESET',
}

export const enum SubmissionModalStatus {
  ACTIVE = 'ACTIVE',
  DISABLE = 'DISABLE',
}

export const initialState: SubmissionPageInitialState = {
  error: null,
  questionAnswered: [],
  selectedFile: null,
  selectedSectionIndex: 0,
};

export const submissionReducer = createReducer(initialState, [
  ...commonHandlers,
  ...countdownHandlers,
  ...questionHandlers,
  ...sectionHandlers,
  ...submissionHandlers,
]);
