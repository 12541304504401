import { Assignment, AssignmentProps, SubmissionSummary } from '@module/assignment';
import { RootReducerState } from 'redux/root.reducer';
import { SubmissionPageStatus } from './submission.reducer';

export const getAssignmentSelector = (state: RootReducerState): Assignment<AssignmentProps> => {
  return state.submissionSubmitPage.assignment;
};

export const getAssignmentStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionSubmitPage.getAssignmentByIdStatus;
};

export const getSubmissionSelector = (state: RootReducerState): SubmissionSummary => {
  return state.submissionSubmitPage.submission;
};
export const getSubmissionSubmitStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionSubmitPage.getSubmissionSubmitUseCaseStatus;
};
