import { Action } from '@lib/plugin-redux-core';
import { SubmissionPageStatus, SubmissionSubmitPageState } from './submission.reducer';

export const handleGetSubmissionSubmitHandler = {
  executing: (state: SubmissionSubmitPageState) => {
    return {
      ...state,
      getSubmissionSubmitUseCaseStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionSubmitPageState, action: Action) => {
    return {
      ...state,
      submission: action.payload.submission,
      getSubmissionSubmitUseCaseStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionSubmitPageState, action: Action) => {
    return {
      ...state,
      getSubmissionSubmitUseCaseStatus: SubmissionPageStatus.ERROR,
      error: action.payload?.error,
    };
  },
};

export const handleGetAssignmentByIdHandler = {
  executing: (state: SubmissionSubmitPageState) => {
    return {
      ...state,
      getAssignmentByIdStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionSubmitPageState, action: Action) => {
    return {
      ...state,
      assignment: action.payload,
      getAssignmentByIdStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionSubmitPageState, action: Action) => {
    return {
      ...state,
      getAssignmentByIdStatus: SubmissionPageStatus.ERROR,
      error: action.payload?.error,
    };
  },
};
