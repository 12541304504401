import { createReducer } from '@lib/plugin-redux-core';
import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_EXAMINATION_SUBMISSION_PREVIEW_USE_CASE,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { UseCaseError } from '@digibricks/domains';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import {
  handleGetAssignmentByIdUseCase,
  handleGetSubmissionPreviewUseCase,
} from './examination-submission-preview.handler';

export enum ExaminationSubmissionPreviewPageStatus {
  EXECUTING,
  SUCCESS,
  ERROR,
  RESET,
}

export type GetExaminationSubmissionPreviewUseCaseState = {
  submission: SubmissionSummary;
  formRelease: FormRelease;
  attendeeDetail: AttendeeDetail;
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
  answers: (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[];
  getExaminationSubmissionPreviewStatus: ExaminationSubmissionPreviewPageStatus;
};

type GetAssignmentByIdUseCaseState = {
  getAssignmentByIdStatus: ExaminationSubmissionPreviewPageStatus;
  assignment: Assignment<AssignmentProps>;
};

export type SubmissionPreviewPageInitialState = {
  error?: UseCaseError<any>;
};

export type ExaminationSubmissionPreviewPageState = SubmissionPreviewPageInitialState &
  GetExaminationSubmissionPreviewUseCaseState &
  GetAssignmentByIdUseCaseState;

export const initialState: SubmissionPreviewPageInitialState = {
  error: null,
};

export const examinationSubmissionPreviewReducer = createReducer(initialState, [
  {
    name: GET_EXAMINATION_SUBMISSION_PREVIEW_USE_CASE,
    ...handleGetSubmissionPreviewUseCase,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
]);
