import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, createEpicForUseCase } from '@lib/plugin-redux-core';
import { bootstrapAuthDI, bootstrapDI, UseCaseConfig } from '../di/index';
import { showToastHandler } from '@lib/common';
import getConfig from 'next/config';
import { GET_USER_BY_ID_USE_CASE } from '@module/user';
import {
  GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
  UPDATE_DRAFT_ESSAY_ANSWER_USE_CASE,
} from '@module/assignment';
import { notification } from 'antd';

export const epic$ = new BehaviorSubject(combineEpics());
export const rootEpic = (action$: any, state$: any) =>
  epic$.pipe(mergeMap((epic) => epic(action$, state$, null)));

const cachedUseCases: UseCaseConfig[] = [];
const cachedAuthUseCases: UseCaseConfig[] = [];

export const runAsyncEpics = async () => {
  if (cachedUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapDI();
  cachedUseCases.push(...useCases);
  const epics = cachedUseCases.map((u: UseCaseConfig) => {
    return mappingShowToastHandlerByUseCase(u);
  });
  epics.forEach((e) => epic$.next(e));
};

export const runAuthAsyncEpics = async () => {
  if (cachedAuthUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapAuthDI();
  cachedAuthUseCases.push(...useCases);
  const epics = cachedAuthUseCases.map((u: UseCaseConfig) => {
    return mappingShowToastHandlerByUseCase(u);
  });
  epics.forEach((e) => epic$.next(e));
};

export const unAuthenticationHandler = () => {
  localStorage.clear();
  const { publicRuntimeConfig } = getConfig();
  window.location.href = `${publicRuntimeConfig.app.authentication.page.signIn.url}`;
};

const showCommonErrorToastHandler = (action: Action) => {
  const { error, translation } = action;
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: 'error',
    description: message,
    duration: 5,
  });
};

export const mappingShowToastHandlerByUseCase = (u: UseCaseConfig) => {
  switch (u.name) {
    case GET_USER_BY_ID_USE_CASE:
    case GET_PARTICIPATED_ASSIGNMENT_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showToastHandler, unAuthenticationHandler);
    default:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showCommonErrorToastHandler,
        unAuthenticationHandler,
      );
  }
};
