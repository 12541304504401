import { useAccessToken } from '@lib/common';
import {
  CreateEssayAnswerUseCase,
  CreateFillInBlankAnswerUseCase,
  CreateMultipleChoiceAnswerUseCase,
  CreateSingleChoiceAnswerUseCase,
  GetAnswersUseCase,
  GetSubmissionByIdUseCase,
  GetSubmissionsUseCase,
  ISubmissionRepository,
  SUBMISSION_REPOSITORY,
  UpdateEssayAnswerUseCase,
  UpdateFillInBlankAnswerUseCase,
  UpdateMultipleChoiceAnswerUseCase,
  UpdateSingleChoiceAnswerUseCase,
  UpdateSubmissionUseCase,
  CREATE_ESSAY_ANSWER_USE_CASE,
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  GET_ANSWERS_USE_CASE,
  GET_SUBMISSIONS_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  UPDATE_ESSAY_ANSWER_USE_CASE,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SUBMISSION_USE_CASE,
  GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
  GetSubmissionsResponderUseCase,
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
  DELETE_ESSAY_ANSWER_FILE_USE_CASE,
  DeleteEssayAnswerFileUseCase,
  GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
  GetFormReleaseBySubmissionUseCase,
  GET_SUBMITTED_SUBMISSION_USE_CASE,
  GetAssignmentByIdUseCase,
  GetSubmissionSubmitUseCase,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_SUBMISSION_PREVIEW_USE_CASE,
  GetSubmissionPreviewUseCase,
  GetAttendeeByIdUseCase,
  GET_ATTENDEE_BY_ID_USE_CASE,
  GetSubmissionElapsedTimeUseCase,
  GET_RECORDED_ELAPSED_TIME_USE_CASE,
  SET_RECORDED_ELAPSED_TIME_USE_CASE,
  SetSubmissionElapsedTimeUseCase,
  GET_HOMEWORK_SUBMISSION_PREVIEW_USE_CASE,
  GET_EXAMINATION_SUBMISSION_PREVIEW_USE_CASE,
  GetHomeworkSubmissionPreviewUseCase,
  GetExaminationSubmissionPreviewUseCase,
  GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE,
  GetSubmissionActivityByIdUseCase,
  UPDATE_MATCHING_ANSWER_USE_CASE,
  UpdateMatchingAnswerUseCase,
  submissionRepositoryProvider,
  UPDATE_DRAFT_ESSAY_ANSWER_USE_CASE,
  UpdateDraftEssayAnswerUseCase,
  DELETE_DRAFT_ESSAY_ANSWER_USE_CASE,
  DeleteDraftEssayAnswerUseCase,
  FIND_DRAFT_ESSAY_ANSWER_USE_CASE,
  FindDraftEssayAnswerUseCase,
  REMOVE_KEY_DRAFT_ESSAY_ANSWER_USE_CASE,
  RemoveKeyDraftEssayAnswerUseCase,
  UPLOAD_ANSWER_AUDIO_USE_CASE,
  UploadAnswerAudioUseCase,
} from '@module/assignment';
import {
  DeleteFileUseCase,
  FILE_STORAGE_REPOSITORY,
  IFileStorageRepository,
  SaveFileUseCase,
} from '@module/file-storage';
import {
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  FindFormReleaseByIdUseCase,
  FindQuestionReleasesUseCase,
  IQuestionReleasesRepository,
  QUESTION_RELEASES_REPOSITORY,
} from '@module/form';
import { inject } from '@vercel/analytics/*';

export const submissionUseCaseProviders = [
  {
    provide: GET_SUBMISSIONS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionsUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_ANSWERS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetAnswersUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSION_BY_ID_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionByIdUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_ESSAY_ANSWER_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      fileStorageRepository: IFileStorageRepository,
    ) => {
      return new CreateEssayAnswerUseCase(
        submissionRepository,
        new SaveFileUseCase(fileStorageRepository),
      );
    },
    inject: [SUBMISSION_REPOSITORY, FILE_STORAGE_REPOSITORY],
  },
  {
    provide: UPDATE_ESSAY_ANSWER_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      fileStorageRepository: IFileStorageRepository,
    ) => {
      return new UpdateEssayAnswerUseCase(
        submissionRepository,
        new SaveFileUseCase(fileStorageRepository),
      );
    },
    inject: [SUBMISSION_REPOSITORY, FILE_STORAGE_REPOSITORY],
  },
  {
    provide: UPLOAD_ANSWER_AUDIO_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      fileStorageRepository: IFileStorageRepository,
    ) => {
      return new UploadAnswerAudioUseCase(
        submissionRepository,
        new SaveFileUseCase(fileStorageRepository),
      );
    },
    inject: [SUBMISSION_REPOSITORY, FILE_STORAGE_REPOSITORY],
  },
  {
    provide: DELETE_ESSAY_ANSWER_FILE_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      fileStorageRepository: IFileStorageRepository,
    ) => {
      return new DeleteEssayAnswerFileUseCase(
        submissionRepository,
        new DeleteFileUseCase(fileStorageRepository),
      );
    },
    inject: [SUBMISSION_REPOSITORY, FILE_STORAGE_REPOSITORY],
  },
  {
    provide: CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateSingleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateSingleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateMultipleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateMultipleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateFillInBlankAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateFillInBlankAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_MATCHING_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateMatchingAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      assignmentRepository: IAssignmentRepository,
      questionReleasesRepository: IQuestionReleasesRepository,
    ) => {
      return new GetSubmissionsResponderUseCase(
        submissionRepository,
        assignmentRepository,
        questionReleasesRepository,
      );
    },
    inject: [SUBMISSION_REPOSITORY, ASSIGNMENT_REPOSITORY, QUESTION_RELEASES_REPOSITORY],
  },
  {
    provide: GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
    useFactory: (
      getSubmissionByIdUseCase: GetSubmissionByIdUseCase,
      getAssignmentByIdUseCase: GetAssignmentByIdUseCase,
      findFormReleaseByIdUseCase: FindFormReleaseByIdUseCase,
    ) => {
      return new GetFormReleaseBySubmissionUseCase(
        getSubmissionByIdUseCase,
        getAssignmentByIdUseCase,
        findFormReleaseByIdUseCase,
      );
    },
    inject: [
      GET_SUBMISSION_BY_ID_USE_CASE,
      GET_ASSIGNMENT_BY_ID_USE_CASE,
      FIND_FORM_RELEASE_BY_ID_USE_CASE,
    ],
  },
  {
    provide: GET_SUBMITTED_SUBMISSION_USE_CASE,
    useFactory: (
      getSubmissionByIdUseCase: GetSubmissionByIdUseCase,
      getAssignmentByIdUseCase: GetAssignmentByIdUseCase,
    ) => {
      return new GetSubmissionSubmitUseCase(getSubmissionByIdUseCase, getAssignmentByIdUseCase);
    },
    inject: [GET_SUBMISSION_BY_ID_USE_CASE, GET_ASSIGNMENT_BY_ID_USE_CASE],
  },
  {
    provide: GET_SUBMISSION_PREVIEW_USE_CASE,
    useFactory: (
      getSubmissionByIdUseCase: GetSubmissionByIdUseCase,
      getAssignmentByIdUseCase: GetAssignmentByIdUseCase,
      findFormReleaseByIdUseCase: FindFormReleaseByIdUseCase,
      getAnswersUseCase: GetAnswersUseCase,
      getAttendeeByIdUseCase: GetAttendeeByIdUseCase,
      findQuestionReleasesUseCase: FindQuestionReleasesUseCase,
    ) => {
      return new GetSubmissionPreviewUseCase(
        getSubmissionByIdUseCase,
        getAssignmentByIdUseCase,
        findFormReleaseByIdUseCase,
        getAnswersUseCase,
        getAttendeeByIdUseCase,
        findQuestionReleasesUseCase,
      );
    },
    inject: [
      GET_SUBMISSION_BY_ID_USE_CASE,
      GET_ASSIGNMENT_BY_ID_USE_CASE,
      FIND_FORM_RELEASE_BY_ID_USE_CASE,
      GET_ANSWERS_USE_CASE,
      GET_ATTENDEE_BY_ID_USE_CASE,
      FIND_QUESTION_RELEASES_USE_CASE,
    ],
  },
  {
    provide: GET_HOMEWORK_SUBMISSION_PREVIEW_USE_CASE,
    useFactory: (
      getSubmissionByIdUseCase: GetSubmissionByIdUseCase,
      findFormReleaseByIdUseCase: FindFormReleaseByIdUseCase,
      getAnswersUseCase: GetAnswersUseCase,
      getAttendeeByIdUseCase: GetAttendeeByIdUseCase,
      findQuestionReleasesUseCase: FindQuestionReleasesUseCase,
    ) => {
      return new GetHomeworkSubmissionPreviewUseCase(
        getSubmissionByIdUseCase,
        findFormReleaseByIdUseCase,
        getAnswersUseCase,
        getAttendeeByIdUseCase,
        findQuestionReleasesUseCase,
      );
    },
    inject: [
      GET_SUBMISSION_BY_ID_USE_CASE,
      FIND_FORM_RELEASE_BY_ID_USE_CASE,
      GET_ANSWERS_USE_CASE,
      GET_ATTENDEE_BY_ID_USE_CASE,
      FIND_QUESTION_RELEASES_USE_CASE,
    ],
  },
  {
    provide: GET_EXAMINATION_SUBMISSION_PREVIEW_USE_CASE,
    useFactory: (
      getSubmissionByIdUseCase: GetSubmissionByIdUseCase,
      findFormReleaseByIdUseCase: FindFormReleaseByIdUseCase,
      getAnswersUseCase: GetAnswersUseCase,
      getAttendeeByIdUseCase: GetAttendeeByIdUseCase,
      findQuestionReleasesUseCase: FindQuestionReleasesUseCase,
    ) => {
      return new GetExaminationSubmissionPreviewUseCase(
        getSubmissionByIdUseCase,
        findFormReleaseByIdUseCase,
        getAnswersUseCase,
        getAttendeeByIdUseCase,
        findQuestionReleasesUseCase,
      );
    },
    inject: [
      GET_SUBMISSION_BY_ID_USE_CASE,
      FIND_FORM_RELEASE_BY_ID_USE_CASE,
      GET_ANSWERS_USE_CASE,
      GET_ATTENDEE_BY_ID_USE_CASE,
      FIND_QUESTION_RELEASES_USE_CASE,
    ],
  },
  {
    provide: GET_RECORDED_ELAPSED_TIME_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionElapsedTimeUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: SET_RECORDED_ELAPSED_TIME_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new SetSubmissionElapsedTimeUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionActivityByIdUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: FIND_DRAFT_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new FindDraftEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_DRAFT_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateDraftEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: DELETE_DRAFT_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new DeleteDraftEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: REMOVE_KEY_DRAFT_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new RemoveKeyDraftEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
];
