import { UseCaseStateGenerator, Action } from '@lib/plugin-redux-core';
import {
  SET_IS_AUTO_PLAY_AUDIO_SECTION,
  UPDATE_CURRENT_PLAYING_AUDIO,
  UPDATE_SUBMISSION_AUDIOS,
} from 'redux/root.action';
import {
  SetIsAutoPlayAudioSectionState,
  UpdateCurrentPlayingAudioState,
  UpdateSubmissionAudioVersionState,
} from './section.states';
import { SubmissionPageState } from '../submission.state';

const handleUpdateCurrentPlayingAudio: UseCaseStateGenerator<UpdateCurrentPlayingAudioState> = {
  name: UPDATE_CURRENT_PLAYING_AUDIO,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      currentPlayingAudio: payload.audioFile,
    };
  },
};

const handleUpdateSubmissionAudios: UseCaseStateGenerator<UpdateSubmissionAudioVersionState> = {
  name: UPDATE_SUBMISSION_AUDIOS,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    const updatedAudios = state.submissionAudios ? [...state.submissionAudios] : [];

    if (!payload?.id) {
      return {
        ...state,
        submissionAudios: updatedAudios,
      };
    }

    const existingAudioIndex = updatedAudios.findIndex(
      (submissionAudio) => submissionAudio.id === payload.id,
    );

    if (existingAudioIndex !== -1) {
      updatedAudios[existingAudioIndex] = {
        ...updatedAudios[existingAudioIndex],
        ...payload,
      };
    } else {
      updatedAudios.push(payload);
    }

    return {
      ...state,
      submissionAudios: updatedAudios,
      submissionAudiosVersion: state.submissionAudiosVersion || 0 + 1,
    };
  },
};

const handleSetIsAutoPlayAudioSection: UseCaseStateGenerator<SetIsAutoPlayAudioSectionState> = {
  name: SET_IS_AUTO_PLAY_AUDIO_SECTION,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      isAutoPlayAudioSection: action.payload,
    };
  },
};

export const sectionHandlers = [
  handleUpdateCurrentPlayingAudio,
  handleSetIsAutoPlayAudioSection,
  handleUpdateSubmissionAudios,
];
