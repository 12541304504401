import { UseCaseStateGenerator, RequestStatus, Action } from '@lib/plugin-redux-core';
import {
  PAUSE_PRACTICE_SUBMISSION_USE_CASE,
  RESUME_PRACTICE_SUBMISSION_USE_CASE,
  SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  submissionStatus,
  SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
  SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
  GET_RECORDED_ELAPSED_TIME_USE_CASE,
  ConfigType,
  ConfigTypeProps,
  SubmissionSummary,
} from '@module/assignment';
import { SET_IS_END_TIME_PRACTICE_SUBMISSION } from 'redux/root.action';
import { SubmissionPageStatus } from '../submission.reducer';
import { SubmissionPageState } from '../submission.state';
import {
  PausePracticeSubmissionUseCaseState,
  ResumePracticeSubmissionUseCaseState,
  SubmitSubmissionUseCaseState,
  SetIsEndTimePracticeSubmissionState,
  GetRecordedElapsedTimeState,
} from './countdown.states';

const onUpdateStatusSubmission = (
  submission: SubmissionSummary,
  status: ConfigTypeProps,
): SubmissionSummary => {
  submission.updateStatus(ConfigType.create(status).getValue());

  return submission;
};

const handlePausePracticeSubmissionUseCase: UseCaseStateGenerator<PausePracticeSubmissionUseCaseState> =
  {
    name: PAUSE_PRACTICE_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        isCountingDown: false,
        pausePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        pausePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
      };
    },
    error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        pausePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
      };
    },
  };

const handleResumePracticeSubmissionUseCase: UseCaseStateGenerator<ResumePracticeSubmissionUseCaseState> =
  {
    name: RESUME_PRACTICE_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        isCountingDown: true,
        resumePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        resumePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
      };
    },
    error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        resumePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
      };
    },
  };

const handleSubmitPracticeSubmissionUseCase: UseCaseStateGenerator<SubmitSubmissionUseCaseState> = {
  name: SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;

    const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
    return {
      ...state,
      submission: newSubmission,
      submitSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

const handleSubmitHomeworkSubmissionUseCase: UseCaseStateGenerator<SubmitSubmissionUseCaseState> = {
  name: SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;

    const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
    return {
      ...state,
      submission: newSubmission,
      submitSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

const handleSubmitExaminationSubmissionUseCase: UseCaseStateGenerator<SubmitSubmissionUseCaseState> =
  {
    name: SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        submitSubmissionStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      const { submission } = state;

      const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
      return {
        ...state,
        submission: newSubmission,
        submitSubmissionStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        submitSubmissionStatus: RequestStatus.ERROR,
      };
    },
  };

const handleSetIsEndTimePracticeSubmission: UseCaseStateGenerator<SetIsEndTimePracticeSubmissionState> =
  {
    name: SET_IS_END_TIME_PRACTICE_SUBMISSION,
    executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      return {
        ...state,
        setIsEndTimePracticeSubmissionStatus: action.payload,
      };
    },
  };

const handleGetSubmissionElapsedTime: UseCaseStateGenerator<GetRecordedElapsedTimeState> = {
  name: GET_RECORDED_ELAPSED_TIME_USE_CASE,
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      recordedElapsedTime: action.payload,
      getRecordedElapsedTimeStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

export const countdownHandlers = [
  handleGetSubmissionElapsedTime,
  handleSetIsEndTimePracticeSubmission,
  handleSubmitExaminationSubmissionUseCase,
  handleSubmitHomeworkSubmissionUseCase,
  handleSubmitPracticeSubmissionUseCase,
  handleResumePracticeSubmissionUseCase,
  handlePausePracticeSubmissionUseCase,
];
