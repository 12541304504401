import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import { CreateAttendeeUseCaseState, CreateSubmissionUseCaseState } from './start-submission.state';
import {
  CREATE_ATTENDEE_USE_CASE,
  CREATE_EXAMINATION_SUBMISSION_USE_CASE,
  CREATE_HOMEWORK_SUBMISSION_USE_CASE,
  CREATE_PRACTICE_SUBMISSION_USE_CASE,
} from '@module/assignment';
import { AssignmentDetailPageState } from '../assignment-detail.state';

const handleCreateAttendeeUseCase: UseCaseStateGenerator<CreateAttendeeUseCaseState> = {
  name: CREATE_ATTENDEE_USE_CASE,
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createAttendeeStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      attendeeId: action.payload.id,
      createAttendeeStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createAttendeeStatus: RequestStatus.ERROR,
    };
  },
};

const handleCreatePracticeSubmissionUseCase: UseCaseStateGenerator<CreateSubmissionUseCaseState> = {
  name: CREATE_PRACTICE_SUBMISSION_USE_CASE,
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      createSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

const handleCreateHomeworkSubmissionUseCase: UseCaseStateGenerator<CreateSubmissionUseCaseState> = {
  name: CREATE_HOMEWORK_SUBMISSION_USE_CASE,
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      createSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

const handleCreateExaminationSubmissionUseCase: UseCaseStateGenerator<CreateSubmissionUseCaseState> =
  {
    name: CREATE_EXAMINATION_SUBMISSION_USE_CASE,
    executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
      return {
        ...state,
        createSubmissionStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
      return {
        ...state,
        newSubmissionId: action.payload.id.toString(),
        createSubmissionStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        createSubmissionStatus: RequestStatus.ERROR,
      };
    },
  };

export const startSubmissionHandlers = [
  handleCreateAttendeeUseCase,
  handleCreatePracticeSubmissionUseCase,
  handleCreateHomeworkSubmissionUseCase,
  handleCreateExaminationSubmissionUseCase,
];
