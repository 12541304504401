import { Action } from '@lib/plugin-redux-core';
import {
  SubmissionPreviewPageState,
  SubmissionPreviewPageStatus,
} from './submission-preview.reducer';
import { QuestionReleases } from '@module/form';

export const handleGetSubmissionPreviewUseCase = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getSubmissionPreviewStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    const questions = payload.questionReleases;
    const sortedQuestions = QuestionReleases.sortQuestions(questions);

    return {
      ...state,
      submission: payload.submission,
      assignment: payload.assignment,
      formRelease: payload.formRelease,
      attendeeDetail: payload.attendee,
      questionReleases: sortedQuestions,
      answers: payload.answers,
      getSubmissionPreviewStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
};
