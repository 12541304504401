import { RequestStatus, Action } from '@lib/plugin-redux-core';
import { DashboardState } from './dashboard.reducer';
import { HomeworkAssignment, PracticeAssignment } from '@module/assignment';

export const handlerGetAssignmentsForResponder = {
  executing: (state: DashboardState): DashboardState => {
    return {
      ...state,
      getAssignmentsForResponderStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: DashboardState, action: Action): DashboardState => {
    const { payload } = action;

    return {
      ...state,
      assignments: payload as (HomeworkAssignment | PracticeAssignment)[],
      getAssignmentsForResponderStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: DashboardState, action: Action): DashboardState => {
    const { error } = action;

    return {
      ...state,
      error,
      getAssignmentsForResponderStatus: RequestStatus.ERROR,
    };
  },
};
