import { createReducer } from '@lib/plugin-redux-core';
import { commonHandlers } from './assignment-detail.handler';
import { startSubmissionHandlers } from './start-submission';
import { passwordVerificationHandlers } from './password-verification';
import { initialState } from './assignment-detail.state';

export const assignmentDetailReducer = createReducer(initialState, [
  ...commonHandlers,
  ...startSubmissionHandlers,
  ...passwordVerificationHandlers,
]);
