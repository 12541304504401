import { createReducer } from '@lib/plugin-redux-core';
import {
  GET_SUBMITTED_SUBMISSION_USE_CASE,
  Assignment,
  AssignmentProps,
  SubmissionSummary,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
} from '@module/assignment';
import {
  handleGetAssignmentByIdHandler,
  handleGetSubmissionSubmitHandler,
} from './submission.handler';

export const enum SubmissionPageStatus {
  EXECUTE = 'EXECUTE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RESET = 'RESET',
}

type GetSubmissionSubmitUseCaseState = {
  submission: SubmissionSummary;
  getSubmissionSubmitUseCaseStatus: SubmissionPageStatus;
};

type GetAssignmentByIdUseCaseState = {
  assignment: Assignment<AssignmentProps>;
  getAssignmentByIdStatus: SubmissionPageStatus;
};

export type SubmissionSubmitPageState = GetSubmissionSubmitUseCaseState &
  GetAssignmentByIdUseCaseState;

export const initialState = {
  error: null,
};

export const submissionSubmitReducer = createReducer(initialState, [
  {
    name: GET_SUBMITTED_SUBMISSION_USE_CASE,
    ...handleGetSubmissionSubmitHandler,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdHandler,
  },
]);
