import {
  GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
  GET_QUESTION_RELEASES_BY_FORM_RELEASES_USE_CASE,
  GetFormReleaseBySubmissionUseCase,
  GetQuestionReleasesByFormReleasesUseCase,
} from '@module/assignment';
import {
  FindFormReleaseByIdUseCase,
  FORM_RELEASE_REPOSITORY,
  IFormReleaseRepository,
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
} from '@module/form';

export const formReleaseUseCaseProviders = [
  {
    provide: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    useFactory: (FormReleaseRepository: IFormReleaseRepository) => {
      return new FindFormReleaseByIdUseCase(FormReleaseRepository);
    },
    inject: [FORM_RELEASE_REPOSITORY],
  },
  {
    provide: GET_QUESTION_RELEASES_BY_FORM_RELEASES_USE_CASE,
    useFactory: (getFormReleaseBySubmissionUseCase: GetFormReleaseBySubmissionUseCase) => {
      return new GetQuestionReleasesByFormReleasesUseCase(getFormReleaseBySubmissionUseCase);
    },
    inject: [GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE],
  },
];
