import { RequestStatus } from '@lib/plugin-redux-core';
import {
  HomeworkAssignment,
  ParticipatedAssignmentResponder,
  PracticeAssignment,
} from '@module/assignment';
import { RootReducerState } from 'redux/root.reducer';

export const participatedAssignmentResponseSelector = (
  state: RootReducerState,
): ParticipatedAssignmentResponder[] => state.dashboardPage.participatedAssignmentResponse;

export const isParticipatedAssignmentLoadingSelector = (state: RootReducerState): boolean =>
  state.dashboardPage.isParticipatedAssignmentLoading;

// Get Assignment for responder
export const assignmentForResponderSelector = (
  state: RootReducerState,
): (HomeworkAssignment | PracticeAssignment)[] => state.dashboardPage.assignments;

export const getAssignmentsForResponderStatusSelector = (state: RootReducerState): RequestStatus =>
  state.dashboardPage.getAssignmentsForResponderStatus;
