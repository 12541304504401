import { Action, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import { CHANGE_SECTION_SELECTED_USE_CASE, UPDATE_SUBMISSION_AUDIOS } from 'redux/root.action';
import { SubmissionPageStatus } from '../submission.reducer';
import { SubmissionPageState } from '../submission.state';
import {
  ChangeSelectedSectionState,
  UpdateSubmissionAudioVersionState,
} from './section-summary.states';

const handleChangeSectionUseCase: UseCaseStateGenerator<ChangeSelectedSectionState> = {
  name: CHANGE_SECTION_SELECTED_USE_CASE,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.EXECUTE,
      selectedSectionIndex: payload.selectedSectionIndex,
      selectedSection: state.formRelease.getSectionByIndex(payload.selectedSectionIndex),
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

const handleUpdateSubmissionAudios: UseCaseStateGenerator<UpdateSubmissionAudioVersionState> = {
  name: UPDATE_SUBMISSION_AUDIOS,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    const updatedAudios = state.submissionAudios ? [...state.submissionAudios] : [];

    if (!payload?.id) {
      return {
        ...state,
        submissionAudios: updatedAudios,
      };
    }

    const existingAudioIndex = updatedAudios.findIndex(
      (submissionAudio) => submissionAudio.id === payload.id,
    );

    if (existingAudioIndex !== -1) {
      updatedAudios[existingAudioIndex] = {
        ...updatedAudios[existingAudioIndex],
        ...payload,
      };
    } else {
      updatedAudios.push(payload);
    }

    return {
      ...state,
      submissionAudios: updatedAudios,
      submissionAudiosVersion: state.submissionAudiosVersion || 0 + 1,
    };
  },
};

export const submissionHandlers = [handleChangeSectionUseCase, handleUpdateSubmissionAudios];
