import {
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
  CREATE_EXAMINATION_SUBMISSION_USE_CASE,
  CreateExaminationSubmissionUseCase,
  SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
  SubmitExaminationSubmissionsUseCase,
  IExaminationSubmissionRepository,
  EXAMINATION_SUBMISSION_REPOSITORY,
  UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
  UpdateExaminationSubmissionUseCase,
} from '@module/assignment';

export const examinationSubmissionUseCaseProviders = [
  {
    provide: CREATE_EXAMINATION_SUBMISSION_USE_CASE,
    useFactory: (
      examinationSubmissionRepository: IExaminationSubmissionRepository,
      assignmentRepository: IAssignmentRepository,
    ) => {
      return new CreateExaminationSubmissionUseCase(
        examinationSubmissionRepository,
        assignmentRepository,
      );
    },
    inject: [EXAMINATION_SUBMISSION_REPOSITORY, ASSIGNMENT_REPOSITORY],
  },
  {
    provide: SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
    useFactory: (examinationSubmissionRepository: IExaminationSubmissionRepository) => {
      return new SubmitExaminationSubmissionsUseCase(examinationSubmissionRepository);
    },
    inject: [EXAMINATION_SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
    useFactory: (examinationSubmissionRepository: IExaminationSubmissionRepository) => {
      return new UpdateExaminationSubmissionUseCase(examinationSubmissionRepository);
    },
    inject: [EXAMINATION_SUBMISSION_REPOSITORY],
  },
];
