import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
  UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
  UPDATE_PRACTICE_SUBMISSION_USE_CASE,
  GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE,
  GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
} from '@module/assignment';
import { SubmissionPageStatus } from './submission.reducer';
import {
  GetFormReleaseByIdUseCaseState,
  GetResponderSubmissionActivityUseCaseState,
  SubmissionPageState,
  UpdateExaminationSubmissionUseCaseState,
  UpdateHomeworkSubmissionUseCaseState,
  UpdatePracticeSubmissionUseCaseState,
  UpdateSubmissionAudioVersionState,
} from './submission.state';
import { UPDATE_SUBMISSION_AUDIOS } from 'redux/root.action';

const handleUpdateHomeworkSubmissionUseCase: UseCaseStateGenerator<UpdateHomeworkSubmissionUseCaseState> =
  {
    name: UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { payload } = action;

      return {
        ...state,
        updateHomeworkSubmissionStatus: RequestStatus.EXECUTE,
        currentPlayingAudio: payload?.input?.audioPlayStats
          ? {
              id: payload.input.audioPlayStats.audioFileId,
            }
          : undefined,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        updateHomeworkSubmissionStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        updateHomeworkSubmissionStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        updateHomeworkSubmissionStatus: RequestStatus.RESET,
      };
    },
  };

const handleUpdateExaminationSubmissionUseCase: UseCaseStateGenerator<UpdateExaminationSubmissionUseCaseState> =
  {
    name: UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { payload } = action;

      return {
        ...state,
        updateExaminationSubmissionStatus: RequestStatus.EXECUTE,
        currentPlayingAudio: payload?.input?.audioPlayStats
          ? {
              id: payload.input.audioPlayStats.audioFileId,
            }
          : undefined,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      return { ...state, updateExaminationSubmissionStatus: RequestStatus.SUCCESS };
    },
    error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        updateExaminationSubmissionStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        updateExaminationSubmissionStatus: RequestStatus.RESET,
      };
    },
  };

const handleUpdatePracticeSubmissionUseCase: UseCaseStateGenerator<UpdatePracticeSubmissionUseCaseState> =
  {
    name: UPDATE_PRACTICE_SUBMISSION_USE_CASE,
    executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { payload } = action;
      return {
        ...state,
        updatePracticeSubmissionStatus: RequestStatus.EXECUTE,
        currentPlayingAudio: payload?.input?.audioPlayStats
          ? {
              id: payload.input.audioPlayStats.audioFileId,
            }
          : undefined,
      };
    },
    success: (state: SubmissionPageState): SubmissionPageState => {
      return { ...state, updatePracticeSubmissionStatus: RequestStatus.SUCCESS };
    },
    error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        updatePracticeSubmissionStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        updatePracticeSubmissionStatus: RequestStatus.RESET,
      };
    },
  };

const handleGetSubmissionActivityUseCase: UseCaseStateGenerator<GetResponderSubmissionActivityUseCaseState> =
  {
    name: GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE,
    executing: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        getSubmissionActivityStatus: SubmissionPageStatus.EXECUTE,
      };
    },
    success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
      return {
        ...state,
        submissionActivity: action.payload,
        getSubmissionActivityStatus: SubmissionPageStatus.SUCCESS,
      };
    },
    error: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        getSubmissionActivityStatus: SubmissionPageStatus.ERROR,
      };
    },
    reset: (state: SubmissionPageState): SubmissionPageState => {
      return {
        ...state,
        getSubmissionActivityStatus: SubmissionPageStatus.RESET,
      };
    },
  };

const handleUpdateSubmissionAudios: UseCaseStateGenerator<UpdateSubmissionAudioVersionState> = {
  name: UPDATE_SUBMISSION_AUDIOS,
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    const updatedAudios = state.submissionAudios ? [...state.submissionAudios] : [];

    if (!payload?.id) {
      return {
        ...state,
        submissionAudios: updatedAudios,
      };
    }

    const existingAudioIndex = updatedAudios.findIndex(
      (submissionAudio) => submissionAudio.id === payload.id,
    );

    if (existingAudioIndex !== -1) {
      updatedAudios[existingAudioIndex] = {
        ...updatedAudios[existingAudioIndex],
        ...payload,
      };
    } else {
      updatedAudios.push(payload);
    }

    return {
      ...state,
      submissionAudios: updatedAudios,
      submissionAudiosVersion: state.submissionAudiosVersion || 0 + 1,
    };
  },
};

const handleGetFormReleaseBySubmission: UseCaseStateGenerator<GetFormReleaseByIdUseCaseState> = {
  name: GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { submission, assignment, formRelease } = action.payload;
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.SUCCESS,
      submission,
      selectedSection: formRelease.sections[state.selectedSectionIndex],
      assignment,
      formRelease,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const commonHandlers = [
  handleGetFormReleaseBySubmission,
  handleUpdateHomeworkSubmissionUseCase,
  handleUpdateExaminationSubmissionUseCase,
  handleUpdatePracticeSubmissionUseCase,
  handleUpdateSubmissionAudios,
  handleGetSubmissionActivityUseCase,
];
